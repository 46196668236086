<template>
  <ed-table
  :key="$root.$session.versao"
     strModel="Documento/Modelo"
    :headers="arrayHeaders"
    :rows="arrayRows"
    :pagination="objPagination"
    :filter="formFiltros"
    :loading="loading"
    @register="onRegister"
    @edit="onEdit"
    @search="getModelo"
  />
</template>

<script>
import { EdTable } from "@/components/common/form";
export default {
  name: "EdModelo",
  props: {
    intTipoModeloId: {
      type: [Number],
      required: true,
      default: null,
    },
    strParametro: {
      type: [String],
      required: false,
      default: null,
    },
  },
  components: {
    EdTable,
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      bind: {
        objTipoModelo: null,
        arrayParametros:[]
      },
      loading: false,
      arrayHeaders: [
        {
          text: "Nome",
          sortable: true,
          value: "strNome",
        },
        {
          text: "Ativo",
          sortable: true,
          value: "boolAtivo",
        },
         {
          text: "Padrão",
          sortable: true,
          value: "boolPadrao",
        },
        {
          text: "Empresas vinculadas",
          sortable: true,
          value: "empresas",
          collapse: 5,
          collapseLabel: "Empresas",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros:{
        intTipoDocumentoId:this.intTipoModeloId
      }
    };
  },
  watch: {},
  computed: {},
  methods: {
    initialize() {
      this.$root.$dialog.loading(true);

      Promise.all([
        this.$root.$request.get("Common/Tipo", {
          intId: this.intTipoModeloId
        }),
        this.strParametro ? 

        this.$root.$request.get("Common/Parametro", {
          strChave: this.strParametro
        })
        
        : null
      ]).then(([objTipoModelo,objParametro]) => {
        this.$root.$dialog.loading(false);

        if (objTipoModelo && objTipoModelo.status == 200) {
          this.bind.objTipoModelo = objTipoModelo.result;
        }

        if (objParametro && objParametro.status == 200) {
          this.bind.arrayParametros = objParametro.result.parametros;
        }

        this.getModelo();
      });
    },

    onRegister() {
      this.$router.push({
        name: this.$route.name+'.register'
      });
    },

    onEdit(data) {
      this.$router.push({
        name: this.$route.name+'.edit',
        params: { intId: data.intId },
      });
    },

    getModelo() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("Documento/Modelo", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objModelo) => {
              let empresas = [];
              objModelo.empresas.forEach((objItem) => {
                empresas.push(objItem.empresa.strNome);
              });

              let item = {
                intId: objModelo.intId,
                strNome: objModelo.strNome,
                boolAtivo: this.$utilidade.parseBoolean(objModelo.boolAtivo),
                boolPadrao: this.$utilidade.parseBoolean(objModelo.boolPadrao),
                empresas: empresas.join("<br>"),
                _item: objModelo,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
